.definitionList {
	display: grid;
	grid-template-columns: auto 1fr;
	column-gap: 10px;
	row-gap: 1px;
	align-items: center;

	dt {
		width: 15px;
		height: 15px;
		border: 1px solid #999;
	}

	dd {
		margin: 0;
		line-height: 0;
	}

	.rule {
		margin-top: 20px;
	}

	/* precipitation */
	.precipitation-extreme {
		background-color: #c01cc4;
	}
	.precipitation-severe {
		background-color: #fe1600;
	}
	.precipitation-moderate {
		background-color: #000770;
	}
	.precipitation-light {
		background-color: #4d5dff;
	}
	.precipitation-very-light {
		background-color: #e4e5ff;
	}
	.drizzle {
		background-color: #858585;
	}
	.hail {
		background-color: #ff69b4;
	}
	.snow {
		background-color: #f28601;
	}
	.wet-snow {
		background-color: #f2fb00;
	}
	.lightning {
		background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsIAAA7CARUoSoAAAAI6SURBVDhPZZLLTxNRFIe/uX0pLzXVyCsGjeIDYlRYSViQwMaFceGKBcUEQly58z9w7cIFGOOmqH+CssNgwJIQjWhCBBMFVKpGebXDtJ3O9dyZqaD+kpNOz/l+99w5Zyz2SH9EaY+zXomL7hrnvTxJVcvPaD3zKs5rgRes43ghzh+z/kKVLpDKTzG0PUF7aRnl2ahYC16kDq+6l3c13Tyw4qStZuzQBiUxFjPczw7jvK9CL1gScp6JlR70YhJt8tkhHOFGDW98Sq+iIg6Dvx6S2kiTkG5iMaVA8ZMCCWryG+MkhLsh/ICeRCnHpm37BcObj0nItf9T4rSYa4JnU998QiI3zYhTzzmli3TkJ2jzdgLAV1RCpmHtg9ixXbORuUHumfAunar0jY6iDGfvVWv7oOEuHOyXc478bTZc8RPKzXJJuTmatEw1LAWSrrVX4Ogd6dz8j1lk+FKeJuVEsC2ZZJj3JfulvA7bT2UTy3KWeY09sqrl9aPYSieZM7vc3biYcyDXInsbVq6DPR0WjITz+UPMKVeTifby2ayjIt/8Q7pvBOF+Dwsiw8V6WS15ZFRLFxnnDOm6flwrEQBlMRcWzUPwvyJTN5zdynhLN7P+oKbfMrZzjZcHUriq2h8IxQ8+H0iuavKmvnOVmZl5xsK0H/rRPRq7LnBz/xID5ec0Sle38IaymFTsBNFIH2v2KdJTrxgdvMXXirny6098aZLLDYdpt7bolM8/WY6zVahhfn2L2dYeZgwTCH4DwAjlZeD123EAAAAASUVORK5CYII=);
		background-repeat: no-repeat;
		background-position: center center;
		border: none;
	}

	/* lightning */
	.lightning6 {
		background-color: #e11900;
	}

	.lightning5 {
		background-color: #d9a100;
	}

	.lightning4 {
		background-color: #d9b600;
	}

	.lightning3 {
		background-color: #e6d700;
	}

	.lightning2 {
		background-color: #faff2f;
	}

	.lightning1 {
		background-color: #ffffbc;
	}

	/* Temperature */
	.c38 {
		background-color: #970033;
	}

	.c37 {
		background-color: #b10022;
	}

	.c36 {
		background-color: #b90025;
	}

	.c35 {
		background-color: #c80026;
	}

	.c34 {
		background-color: #d8002a;
	}

	.c33 {
		background-color: #ed002f;
	}

	.c32 {
		background-color: #fc003e;
	}

	.c31 {
		background-color: #dd0004;
	}

	.c30 {
		background-color: #e00004;
	}

	.c29 {
		background-color: #e90916;
	}

	.c28 {
		background-color: #ed1e31;
	}

	.c27 {
		background-color: #ee4831;
	}

	.c26 {
		background-color: #fc4a06;
	}

	.c25 {
		background-color: #fd6007;
	}

	.c24 {
		background-color: #fd7707;
	}

	.c23 {
		background-color: #fd8e07;
	}

	.c22 {
		background-color: #fda207;
	}

	.c21 {
		background-color: #feb908;
	}

	.c20 {
		background-color: #fed809;
	}

	.c19 {
		background-color: #ffee0a;
		color: inherit;
	}

	.c18 {
		background-color: #ffff0c;
		color: inherit;
	}

	.c17 {
		background-color: #e8f909;
		color: inherit;
	}

	.c16 {
		background-color: #cfef08;
		color: inherit;
	}

	.c15 {
		background-color: #b8e609;
	}

	.c14 {
		background-color: #a0da08;
	}

	.c13 {
		background-color: #89d007;
	}

	.c12 {
		background-color: #76c809;
	}

	.c11 {
		background-color: #50bf06;
	}

	.c10 {
		background-color: #209404;
	}

	.c9 {
		background-color: #1c9f05;
	}

	.c8 {
		background-color: #1cae05;
	}

	.c7 {
		background-color: #21c306;
	}

	.c6 {
		background-color: #26dc06;
	}

	.c5 {
		background-color: #29eb07;
	}

	.c4 {
		background-color: #35ff1b;
	}

	.c3 {
		background-color: #44ff33;
	}

	.c2 {
		background-color: #6cff64;
		color: inherit;
	}

	.c1 {
		background-color: #9aff96;
		color: inherit;
	}

	.c0 {
		background-color: #99fbd0;
		color: inherit;
	}

	.cm1 {
		background-color: #98f7ff;
		color: inherit;
	}

	.cm2 {
		background-color: #6effff;
		color: inherit;
	}

	.cm3 {
		background-color: #41f2f0;
	}

	.cm4 {
		background-color: #32ddf8;
	}

	.cm5 {
		background-color: #26c8ff;
	}

	.cm6 {
		background-color: #1aacfe;
	}

	.cm7 {
		background-color: #1493fe;
	}

	.cm8 {
		background-color: #0e79fe;
	}

	.cm9 {
		background-color: #1169e0;
	}

	.cm10 {
		background-color: #1996d1;
	}

	.cm11 {
		background-color: #1084cd;
	}

	.cm12 {
		background-color: #0c72c5;
	}

	.cm13 {
		background-color: #0963b8;
	}

	.cm14 {
		background-color: #075aab;
	}

	.cm15 {
		background-color: #054fa3;
	}

	.cm16 {
		background-color: #6242ff;
	}

	.cm17 {
		background-color: #6263ff;
	}

	.cm18 {
		background-color: #747aff;
	}

	.cm19 {
		background-color: #8f97ff;
	}

	.cm20 {
		background-color: #a4abfa;
	}

	.cm21 {
		background-color: #a66cbd;
	}

	.cm22 {
		background-color: #b400a1;
	}

	.cm23 {
		background-color: #a500ad;
	}

	.cm24 {
		background-color: #7900ad;
	}

	.cm25 {
		background-color: #7d0bc7;
	}
	/* Weerkaarten - Wind */
	.bft12 {
		background-color: #ff0000;
	}

	.bft11 {
		background-color: #ff2c00;
	}

	.bft10 {
		background-color: #ff5900;
	}

	.bft9 {
		background-color: #ff8500;
	}

	.bft8 {
		background-color: #ffae2f;
	}

	.bft7 {
		background-color: #ffd65d;
	}

	.bft6 {
		background-color: #ffff8c;
	}

	.bft5 {
		background-color: #30d200;
	}

	.bft4 {
		background-color: #49da21;
	}

	.bft3 {
		background-color: #63e143;
	}

	.bft2 {
		background-color: #7dea64;
	}

	.bft1 {
		background-color: #95f085;
	}

	/* Cloudheight */
	.cloudheight0 {
		background-color: #fefefe;
	}

	.cloudheight1 {
		background-color: #aeaeae;
	}

	.cloudheight2 {
		background-color: #6e6e70;
	}

	.cloudheight3 {
		background-color: #745477;
	}

	.cloudheight4 {
		background-color: #6e93bd;
	}

	.cloudheight5 {
		background-color: #0081ff;
	}

	.cloudheight6 {
		background-color: #00fffc;
	}

	.cloudheight7 {
		background-color: #018f5d;
	}

	.cloudheight8 {
		background-color: #21b14f;
	}

	.cloudheight9 {
		background-color: #7afd01;
	}

	.cloudheight10 {
		background-color: #fdff00;
	}

	.cloudheight11 {
		background-color: #f5ac07;
	}

	.cloudheight12 {
		background-color: #ff5102;
	}

	.cloudheight13 {
		background-color: #ef0500;
	}

	.cloudheight14 {
		background-color: #910101;
	}

	.cloudheight15 {
		background-color: #000000;
	}
	/* Weerkaarten - Airquality maps */
	.airquality10 {
		background-color: #ff0000;
	}

	.airquality9 {
		background-color: #ff8500;
	}

	.airquality8 {
		background-color: #ffae2f;
	}

	.airquality7 {
		background-color: #ffd65d;
	}

	.airquality6 {
		background-color: #ffff8c;
	}

	.airquality5 {
		background-color: #1f9405;
	}

	.airquality4 {
		background-color: #21e000;
	}

	.airquality3 {
		background-color: #46fc38;
	}

	.airquality2 {
		background-color: #9cff93;
	}

	.airquality1 {
		background-color: #d0fefc;
	}
	/* Weerkaarten - Neerslag */
	.mm100 {
		background-color: #d2002b;
	}

	.mm90 {
		background-color: #ba1f39;
	}

	.mm80 {
		background-color: #992454;
	}

	.mm70 {
		background-color: #812764;
	}

	.mm60 {
		background-color: #592d66;
	}

	.mm50 {
		background-color: #4c4677;
	}

	.mm40 {
		background-color: #2f5f8c;
	}

	.mm35 {
		background-color: #0085bc;
	}

	.mm30 {
		background-color: #0090c9;
	}

	.mm25 {
		background-color: #009bd3;
	}

	.mm20 {
		background-color: #00acd9;
	}

	.mm15 {
		background-color: #49c0df;
	}

	.mm10 {
		background-color: #77d2e5;
	}

	.mm5 {
		background-color: #9edfe6;
	}

	.mm01 {
		background-color: #8ad2b4;
	}

	.mm0 {
		background-color: #81c770;
	}
	/* Weerkaarten - Luchtvochtigheid */
	.h100 {
		background-color: #304678;
	}

	.h90 {
		background-color: #1a4d67;
	}

	.h80 {
		background-color: #005655;
	}

	.h70 {
		background-color: #005f47;
	}

	.h60 {
		background-color: #00663a;
	}

	.h50 {
		background-color: #008336;
	}

	.h40 {
		background-color: #48a334;
	}

	.h30 {
		background-color: #88c331;
	}

	.h20 {
		background-color: #ffd84a;
	}
	/* Weerkaarten - Zonneschijn */
	.sun14 {
		background-color: #ffb132;
	}

	.sun13 {
		background-color: #ffb636;
	}

	.sun12 {
		background-color: #ffbc3c;
	}

	.sun11 {
		background-color: #ffc341;
	}

	.sun10 {
		background-color: #fbc944;
	}

	.sun9 {
		background-color: #f8ce46;
	}

	.sun8 {
		background-color: #f5d449;
	}

	.sun7 {
		background-color: #f2d94c;
	}

	.sun6 {
		background-color: #e6d748;
	}

	.sun5 {
		background-color: #d7d345;
	}

	.sun4 {
		background-color: #c9d042;
	}

	.sun3 {
		background-color: #bbcc3e;
	}

	.sun2 {
		background-color: #aac939;
	}

	.sun1 {
		background-color: #99c635;
	}

	.sun0 {
		background-color: #88c331;
	}
	/* Muggen */
	.mosquitoradar1 {
		background-color: #009100;
	}

	.mosquitoradar2 {
		background-color: #00ad00;
	}

	.mosquitoradar3 {
		background-color: #8cd600;
	}

	.mosquitoradar4 {
		background-color: #aee700;
	}

	.mosquitoradar5 {
		background-color: #f8e71c;
	}

	.mosquitoradar6 {
		background-color: #f5d423;
	}

	.mosquitoradar7 {
		background-color: #ff9500;
	}

	.mosquitoradar8 {
		background-color: #e85300;
	}

	.mosquitoradar9 {
		background-color: #e8031e;
	}

	.mosquitoradar10 {
		background-color: #ad0200;
	}
	/* UV-Index */
	.uvindex0 {
		background-color: #49da21;
	}

	.uvindex1 {
		background-color: #49da21;
	}

	.uvindex2 {
		background-color: #30d200;
	}

	.uvindex3 {
		background-color: #fff88b;
	}

	.uvindex4 {
		background-color: #fff642;
	}

	.uvindex5 {
		background-color: #fdbb31;
	}

	.uvindex6 {
		background-color: #fd8e24;
	}

	.uvindex7 {
		background-color: #fc103e;
	}

	.uvindex8 {
		background-color: #960a33;
	}

	.uvindex9 {
		background-color: #a66dbc;
	}

	.uvindex10 {
		background-color: #b330a1;
	}
	/* BBQ */
	.bbqradar1 {
		background-color: #a0d4d1;
	}

	.bbqradar2 {
		background-color: #9dcea5;
	}

	.bbqradar3 {
		background-color: #e3d255;
	}

	.bbqradar4 {
		background-color: #f18c33;
	}

	.bbqradar5 {
		background-color: #ea5932;
	}

	.bbqradar6 {
		background-color: #d63332;
	}

	.bbqradar7 {
		background-color: #c8282f;
	}

	.bbqradar8 {
		background-color: #ae2229;
	}

	.bbqradar9 {
		background-color: #921921;
	}

	.bbqradar10 {
		background-color: #5c0d13;
	}
	/* Pollen */
	.pollen0 {
		background-color: #49da21;
	}

	.pollen1 {
		background-color: #49da21;
	}

	.pollen2 {
		background-color: #30d200;
	}

	.pollen3 {
		background-color: #fff88b;
	}

	.pollen4 {
		background-color: #fff642;
	}

	.pollen5 {
		background-color: #fdbb31;
	}

	.pollen6 {
		background-color: #fd8e24;
	}

	.pollen7 {
		background-color: #fc103e;
	}

	.pollen8 {
		background-color: #960a33;
	}

	.pollen9 {
		background-color: #a66dbc;
	}

	.pollen10 {
		background-color: #b330a1;
	}
	/* TravelAdvice */
	.traveladvice0 {
		background-color: #a0cca0;
	}

	.traveladvice1 {
		background-color: #1a9f1a;
	}

	.traveladvice2 {
		background-color: #eeee00;
	}

	.traveladvice3 {
		background-color: #ff8f16;
	}

	.traveladvice4 {
		background-color: #cb0000;
	}
	/* Sneeuwhoogte*/
	.snowdepth400 {
		background-color: #d02231;
	}

	.snowdepth200 {
		background-color: #31608a;
	}

	.snowdepth150 {
		background-color: #1b9cd1;
	}

	.snowdepth100 {
		background-color: #52c0dd;
	}

	.snowdepth50 {
		background-color: #a0dfe5;
	}

	.snowdepth25 {
		background-color: #8cd1b5;
	}

	.snowdepth0 {
		background-color: #31a02a;
	}
}

.definitionListWithoutBorder {
	row-gap: 0px;

	dt {
		border: none;
	}
}

.definitionColumns {
	grid-template-columns: repeat(var(--numberOfColumns), auto 1fr);

	dt,
	dd {
		grid-column-start: var(--colStart);
		grid-row-start: var(--rowStart);
	}
}

.earthquakes {
	row-gap: 8px;

	dt {
		width: 32px;
		height: 32px;
		border: 1px solid var(--color);
		border-radius: 50%;
		position: relative;
		display: grid;
		align-items: center;
		justify-content: center;

		.quake-line {
			display: inline-block;
			border: 1px solid var(--color);
			border-radius: 50%;
			grid-row: 1;
			grid-column: 1;
			align-self: center;
			justify-self: center;
		}

		.quake-inner {
			width: 8px;
			height: 8px;
			background-color: var(--color);
		}
		.quake-middle {
			width: 16px;
			height: 16px;
		}
		.quake-outer {
			width: 24px;
			height: 24px;
		}
	}
}
